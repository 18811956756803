import { getData } from '@/api/balance'

export default {
  namespaced: true,
  state() {
    return {
      dataList: [],
      totalItem: 0
    }
  },
  mutations: {
    setData(state, paylod) {
      const { data, totalItem } = paylod
      state.dataList = [...state.dataList, ...data]
      state.totalItem = totalItem
    },
    restList(state) {
      state.dataList = []
      state.totalItem = 0
    },
    setPrice(sate, paylod) {
      sate.info.price = paylod
    }
  },
  actions: {
    async getData({ commit }, paylod) {
      // const { pageIndex, pageSize } = paylodc
      const { data, totalItem } = await getData(paylod)
      commit('setData', { data, totalItem })
    }
  }
}
