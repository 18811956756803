// 判断内核
export const browser = {
  versions: (function () {
    const u = navigator.userAgent
    // const app = navigator.appVersion
    return {
      // 移动终端浏览器版本信息
      trident: u.indexOf('Trident') > -1, // IE内核
      presto: u.indexOf('Presto') > -1, // opera内核
      webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, // 火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), // 是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或者uc浏览器
      iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, // 是否为iPhone或者QQHD浏览器
      iPad: u.indexOf('iPad') > -1, // 是否iPad
      webApp: u.indexOf('Safari') == -1 // 是否web应该程序，没有头部与底部
    }
  })(),
  language: (navigator.browserLanguage || navigator.language).toLowerCase()
}

// 打开网页来源
export const getSource = () => {
  const ua = navigator.userAgent.toLowerCase() // 获取判断用的对象
  // 在移动设备打开
  if (browser.versions.mobile) {
    // 在微信中打开
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return 'wx'
    }
    // 在新浪微博客户端打开
    if (ua.match(/WeiBo/i) == 'weibo') {
      return 'wb'
    }
    // 在QQ空间打开
    if (ua.match(/QQ/i) == 'qq') {
      return 'qq'
    }
    if (ua.match(/Alipay/i) == 'alipay') {
      return 'zfb'
    }
    if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
      return 'safari'
    }
    // 在IOS浏览器打开
    if (browser.versions.ios) {
      return 'ios'
    }
    // 在安卓浏览器打开
    if (browser.versions.android) {
      return 'android'
    }
  }
  // 在PC浏览器打开
  else {
    // IE浏览器
    if (ua.match(/msie/) !== null || ua.match(/trident/) !== null) {
      const version = ua.match(/msie ([\d.]+)/) !== null ? ua.match(/msie ([\d.]+)/)[1] : ua.match(/rv:([\d.]+)/)[1] // 浏览器版本
      return version
    }
    // 火狐浏览器
    else if (ua.match(/firefox/) !== null) {
      return 'firefox'
    }
    // UC浏览器
    else if (ua.match(/ubrowser/) !== null) {
      return 'uc'
    }
    // 欧朋浏览器
    else if (ua.match(/opera/) !== null) {
      return 'opera'
    }
    // 百度浏览器
    else if (ua.match(/bidubrowser/) !== null) {
      return 'baidu'
    }
    // 搜狗浏览器
    else if (ua.match(/metasr/) !== null) {
      return 'sougou'
    }
    // QQ浏览器
    else if (ua.match(/tencenttraveler/) !== null || ua.match(/qqbrowse/) !== null) {
      return 'QQ'
    }
    // 遨游浏览器
    else if (ua.match(/maxthon/) !== null) {
      return ''
    }
    // 360浏览器
    else if (ua.match(/chrome/) !== null) {
      var is360 = _mime('type', 'application/vnd.chromium.remoting-viewer')

      const _mime = (option, value) => {
        let mimeTypes = navigator.mimeTypes
        for (var mt in mimeTypes) {
          if (mimeTypes[mt][option] == value) {
            return true
          }
        }
        return false
      }

      if (is360) {
        return '360'
      } else {
        return 'zoom'
      }
    }
    // Safari浏览器
    else if (ua.match(/safari/) !== null) {
      return 'safari'
    }
    // 其他浏览器
    else {
      return 'outher'
    }
  }
}
