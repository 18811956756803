import request from '@/utils/request'
const apis = {
  getShareParams: '/drinks/app/v1/user/share'
}

export const getShareParams = (params) => {
  return request({
    method: 'GET',
    url: apis.getShareParams,
    params
  })
}
