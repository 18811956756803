<!--
 * @version: 1.0
 * @Author: 张勇康
 * @Date: 2022-08-05 10:29:03
-->
<template>
  <div class="tabBar">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'TabBar'
}
</script>
<style lang="scss" scoped>
@function rem($px) {
  @return $px / 37.5 * 1rem;
}
.tabBar {
  display: flex;
  justify-content: space-around;
  // align-items: center;
  position: fixed;
  // padding-top: 7px;
  bottom: 0;
  left: 0;
  height: 60px;
  height: calc(60px + constant(safe-area-inset-bottom));
  height: calc(60px + env(safe-area-inset-bottom));
  width: 100%;
  z-index: 9;
  box-shadow: inset 0px 1px 0px 0px rgba(15, 15, 15, 0.1);
  background: #fff;
}
</style>
