<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: 周达
 * @Date: 2022-09-13 09:11:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-19 13:29:08
-->
<template>
  <van-popup :close-on-click-overlay="false" position="bottom" class="pay-content" duration="0.2">
    <div class="pay-tool">
      <div class="pay-tool-title border-bottom">
        <span class="pay-close" @click="backHandle"></span>
        <span class="pay-title">
          <slot name="title">请输入支付密码</slot>
        </span>
      </div>
      <div class="pay-tool-text">
        <div class="pay-tool-info">
          <slot name="info">余额支付</slot>
        </div>
        <div class="pay-tool-price">
          <slot name="price">订单余额支付¥4128.00</slot>
        </div>
      </div>

      <div class="pay-tool-content">
        <div class="pay-tool-inputs">
          <div class="item" v-for="(i, index) in items" :key="index">
            <span class="icon_dot" v-if="password[i]"></span>
          </div>
        </div>
        <div class="pay-tool-link">
          <!-- <div class="link" @click="forgotPassword()">忘记密码？</div> -->
        </div>
      </div>
      <div class="pay-tool-keyboard">
        <ul>
          <li @click="keyUpHandle($event, index)" v-for="(val, index) in keys" :key="index">
            {{ val }}
          </li>
          <li class="del" @click="delHandle"><span class="icon-del"></span></li>
        </ul>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { reactive, toRefs } from 'vue'
const keys = () => [1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0]
export default {
  name: 'payKeyboard',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      items: [0, 1, 2, 3, 4, 5],
      keys: keys(),
      password: ''
    })

    // 关闭密码输入清空密码框
    const backHandle = () => {
      clearPasswordHandle()
      emit('update:show', false)
      emit('closepassword')
    }

    // 输入密码
    const keyUpHandle = (e, index) => {
      console.log(e, index, state)
      let text = state.keys[index].toString()
      let len = state.password.length
      if (!text || len >= 6) {
        return
      }
      state.password = state.password + text
      // 为6位输出密码
      if (state.password.length === 6) {
        passwordData()
      }
    }

    const passwordData = () => {
      // 输出密码
      setTimeout(() => {
        emit('update:show', false)
        emit('passwordData', state.password)
        clearPasswordHandle()
      }, 100)
    }

    const clearPasswordHandle = () => {
      // 清空密码
      state.password = ''
    }

    const delHandle = () => {
      // 删除密码
      if (state.password.length <= 0) {
        return false
      }
      // this.password.shift()
      state.password = state.password.substring(0, state.password.length - 1)
    }

    return {
      backHandle,
      keyUpHandle,
      delHandle,
      ...toRefs(state)
    }
  },
  emits: ['closepassword', 'passwordData', 'update:show']
}
</script>

<style lang="scss" scoped>
@function rem($px) {
  @return $px / 37.5 * 1rem;
}
.pay-tool {
  font-size: rem(32);
  width: rem(375);
  height: rem(444);
  background-color: #fff;
  overflow: hidden;
  .pay-tool-title {
    width: 100%;

    // border-bottom: 1px solid #f7f7f7;
    line-height: rem(22);
    text-align: center;
    overflow: hidden;
    position: relative;
    padding: rem(24) rem(21) rem(16) rem(21);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    .pay-close {
      // float: left;
      font-size: 0.35rem;
      position: absolute;
      background: url('../../assets/images/shopCar/cha.png') no-repeat 0 0/100%; // 这里添加一个关闭icon
      background-size: 100%;
      display: inline-block;
      width: rem(18);
      height: rem(18);
      left: rem(21);
      top: 50%;
      transform: translateY(-30%);
    }
    .pay-title {
      font-size: rem(16);
      font-weight: 500;
      color: #343434;
      height: rem(22);
      line-height: rem(22);
    }
  }
  .pay-tool-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(16);
    .pay-tool-info {
      width: rem(76);
      height: rem(24);
      background: #ffe0df;
      border-radius: rem(12);
      font-size: rem(13);
      font-weight: 500;
      color: #ba2927;
      line-height: rem(24);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: rem(8);
    }
    .pay-tool-price {
      font-size: rem(13);
      font-weight: 400;
      color: #9d9d9d;
      line-height: rem(18);
    }
  }
  .pay-tool-content {
    .pay-tool-inputs {
      width: rem(331);
      height: rem(56);
      margin: auto;
      border: 1px solid #d8d8d8;
      border-radius: 5px;
      box-shadow: 0 0 1px #e6e6e6;
      display: flex;
      .item {
        width: rem(55);
        height: rem(55);
        border-right: rem(1) solid #eee;
        line-height: rem(55);
        text-align: center;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        &:last-child {
          border-right: none;
        }
        .icon_dot {
          display: inline-block;
          width: 0.2rem;
          height: 0.2rem;
          background: #323333;
          border-radius: 20px;
          background-size: cover;
        }
      }
    }
    .pay-tool-link {
      // padding: 0.53333333rem 0.8rem 0;
      text-align: right;
      .link {
        font-size: 0.26rem;
        color: #3c8cfb;
      }
    }
  }
  .pay-tool-keyboard {
    position: absolute;
    left: 0;
    bottom: 0;
    width: rem(375);
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      li {
        width: rem(124);
        height: rem(56);
        line-height: rem(56);
        text-align: center;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        font-size: rem(26);
        font-weight: 500;
        color: #343434;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          border-top: 1px solid #eee;
        }
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(9),
        &:nth-child(12) {
          border-right: none;
        }
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12) {
          border-bottom: none;
          height: rem(64);
          line-height: rem(64);
        }
        &:nth-child(10),
        &:nth-child(12),
        &:active {
          background-color: #eee;
        }
        &:nth-child(12):active {
          background-color: #fff;
        }
      }
      .del {
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-del {
          line-height: rem(56);
          width: rem(32);
          height: rem(32);
          background: url('../../assets/images/shopCar/del.png') no-repeat 0 0/100%;
          display: block;
        }
      }
    }
  }
}
</style>
