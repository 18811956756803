import { createStore } from 'vuex'
import addr from './modules/addr'
import user from './modules/user'
import shop from './modules/shop'
import accountManagement from './modules/accountManagement'
import yb from './modules/yb'
import shopCart from './modules/shopCart'
import balance from './modules/balance'
import beforRouter from './modules/beforRouter'
import keepAlive from './modules/keepAlive'
import information from './modules/information'

// 非模块状态
export default createStore({
  state: {},
  // mutations: mutations,
  actions: {},
  modules: { addr, user, shop, accountManagement, yb, shopCart, balance, beforRouter, keepAlive, information }
})
