import { getShop, getShopDetail } from '@/api/shop'

export default {
  namespaced: true,
  state() {
    return {
      shopData: [],
      setmealTotalItem: 0,
      productTotalItem: 0,
      shopDetail: null,
      productData: []
    }
  },
  mutations: {
    setShopData(state, paylod) {
      const { data, totalItem } = paylod
      state.shopData = [...state.shopData, ...data]
      state.setmealTotalItem = totalItem
    },
    setProductData(state, paylod) {
      const { data, totalItem } = paylod
      state.productData = [...state.productData, ...data]
      state.productTotalItem = totalItem
    },
    setCurrentShop(state, paylod) {
      state.shopDetail = paylod
    },
    clearShop(state) {
      state.shopData = []
      state.productData = []
      state.totalItem = 0
    }
  },
  actions: {
    async getShop({ commit }, paylod) {
      const { pageIndex, pageSize } = paylod
      const setmeal = await getShop({ pageIndex, pageSize, type: 1 })
      commit('setShopData', { data: setmeal.data, totalItem: setmeal.totalItem })
    },
    async getProShop({ commit }, paylod) {
      const { pageIndex, pageSize } = paylod
      const product = await getShop({ pageIndex, pageSize, type: 2 })
      commit('setProductData', { data: product.data, totalItem: product.totalItem })
    },
    async getShopDetail({ commit }, paylod) {
      const { data } = await getShopDetail(paylod)
      commit('setCurrentShop', data)
    }
  }
}
