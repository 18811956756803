import { getShopCart } from '@/api/shopCart'

export default {
  namespaced: true,
  state() {
    return {
      cartData: [],
      totalItem: 0,
      currentOrder: null,
      payMode: 0,
      aChecked: false,
      wxConfig: null
    }
  },
  mutations: {
    setShopCart(state, paylod) {
      const { data, totalItem } = paylod
      state.cartData = data
      localStorage.setItem('cartData', JSON.stringify(paylod))
      state.totalItem = totalItem
    },
    addShopCart(state, paylod) {
      state.shopDetail = paylod
    },
    setCurrentOrder(state, paylod) {
      state.currentOrder = paylod
      localStorage.setItem('currentOrder', JSON.stringify(paylod))
    },
    setChecked(state, paylod) {
      console.log(paylod)
      state.aChecked = paylod
    },
    setPayMode(state, paylod) {
      state.payMode = paylod
    },
    setConfig(state, paylod) {
      state.wxConfig = paylod
    }
  },
  actions: {
    async getShopCart({ commit }) {
      const { data, totalItem } = await getShopCart()
      console.log(data)
      commit('setShopCart', { data, totalItem })
    }
  }
}
