import request from '@/utils/request'
// import axios from 'axios'
const apis = {
  balancePayment: '/drinks/app/v1/pay/balancePayment',
  verifyPasssword: '/drinks/app/v1/pay/verifyPayPassword',
  wxOrder: '/drinks/app/v1/pay/sendWxAlipayPay',
  sendNum: '/drinks/app/v1/pay/bindOppenid',
  withdrawal: '/drinks/app/v1/pay/wxWithdraw',
  withdrawCount: '/drinks/app/v1/pay/queryWithdrawCount',
  goldPayment: '/drinks/app/v1/pay/goldsPayment',
  getWXMessage: '/drinks/app/v1/pay/'
}
// 余额支付
export const balancePayment = (data) => {
  return request({
    method: 'POST',
    url: apis.balancePayment,
    data
  })
}
// 金豆支付
export const goldPayment = (data) => {
  return request({
    method: 'GET',
    url: `${apis.goldPayment}/${data.orderId}/${data.flag}`
  })
}
// 密码验证
export const verifyPasssword = (data) => {
  return request({
    method: 'POST',
    url: apis.verifyPasssword,
    data
  })
}

// 获取openId
// export const getWxOrder = (data) => {
//   return request({
//     method: 'POST',
//     url: apis.wxOrder,
//     data
//   })
// }

export const sendNum = (params) => {
  return request({
    method: 'GET',
    url: apis.sendNum,
    params
  })
}

// 获取微信支付参数
export const getWxOrder = (data) => {
  return request({
    method: 'POST',
    url: apis.wxOrder,
    data
  })
}

// 提现
export const withdrawal = (data) => {
  return request({
    method: 'POST',
    url: apis.withdrawal,
    data
  })
}

// 提现次数
export const withdrawCount = () => {
  return request({
    method: 'get',
    url: apis.withdrawCount
  })
}
// 查询支付是否成功
export const getWXMessage = (orderNo) => {
  // console.log(orderNo)
  return request({
    url: apis.getWXMessage + orderNo,
    method: 'GET'
  })
}
