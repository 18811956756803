<!--
 * @version: 1.0
 * @Author: 张勇康
 * @Date: 2022-08-03 11:19:10
-->
<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="cachedViews">
      <component :is="Component" />
    </keep-alive>
    <!-- <component :is="Component" v-if="!$route.meta.keepAlive" /> -->
  </router-view>
</template>
<script>
// import VConsole from 'vconsole'
// const vConsole = new VConsole({ maxLogNumber: 1000 })
import userApi from '@/api/user'
// import NavBar from '@/components/NavBar'
import { useRouter } from 'vue-router'
import { reactive, ref, watch, toRefs, onBeforeMount, computed } from 'vue'
import { useStore } from 'vuex'
import router from './router/config-router'
// console.log('Hello world', vConsole)
// remove it when you finish debugging
// vConsole.destroy()

export default {
  name: 'App',
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const cachedViews = computed(() => store.state.keepAlive?.cachedViews)
    // const userId = localStorage.getItem('hg-org-userId')
    // const whiteList = ['login', 'register', 'forgetPassword']
    // // console.log(, 'whiteList.includes(to.name)')
    // if (userId && !whiteList.includes(router.currentRoute.value.name)) {
    //   store.dispatch('user/getMineInfo')
    // }
    return {
      cachedViews
    }
  }
}
</script>
<style lang="scss">
@function rem($px) {
  @return $px / 37.5 * 1rem;
}
</style>
