import { getData, getInfo } from '@/api/yb'

export default {
  namespaced: true,
  state() {
    return {
      dataList: [],
      totalItem: 0,
      info: null
    }
  },
  mutations: {
    setData(state, paylod) {
      const { data, totalItem } = paylod
      state.dataList = [...state.dataList, ...data]
      state.totalItem = totalItem
    },
    setInfo(state, paylod) {
      console.log(paylod)
      state.info = paylod
    },
    restList(state) {
      state.dataList = []
      state.totalItem = 0
    },
    clearList(state) {
      state.dataList = []
      state.totalItem = 0
      state.info = null
    },
    setPrice(sate, paylod) {
      sate.info.price = paylod
    }
  },
  actions: {
    async getData({ commit }, paylod) {
      // const { pageIndex, pageSize } = paylodc
      const { data, totalItem } = await getData(paylod)
      commit('setData', { data, totalItem })
    },
    async getInfo({ commit }) {
      const { data } = await getInfo()
      commit('setInfo', data)
    }
  }
}
