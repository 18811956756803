<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: 周达
 * @Date: 2022-09-13 09:11:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-19 11:40:13
-->
<template>
  <van-popup position="bottom" class="shop-modal" duration="0.2">
    <div class="modal-content">
      <div class="card">
        <div class="card-img">
          <img :src="shop.skuList[0].picUrl" alt="" />
        </div>
        <div class="card-context">
          <p class="card-title">{{ shop.goodsInfo?.name }}</p>
          <p class="card-surplusNum">库存：{{ shop.skuList[0].stockNum }}</p>
          <p class="card-price">
            ¥<span>{{ shop.goodsInfo.originPrice.toFixed(2) }}</span>
          </p>
        </div>
      </div>
      <div class="oper">
        <div class="info">数量</div>
        <div class="numOper">
          <span class="limit">(限购{{ shop.goodsInfo.type == '1' ? '9' : '99' }}件)</span>
          <div class="btn">
            <div @click="sub" class="btn-item"><i :class="{ sub: true, sub_i: num > 1 }"></i></div>
            <span>{{ num }}</span>
            <div @click="add" class="btn-item"><i :class="{ add: true, add_i: num >= maxNum }"></i></div>
          </div>
        </div>
      </div>
      <div class="buy">
        <button @click="oper">{{ btnText }}</button>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  name: 'shopModal',
  props: {
    type: {
      type: String,
      default: 'add'
    },
    btnText: {
      type: String,
      default: '确定'
    },
    maxNum: {
      type: Number,
      default: 9
    },
    shop: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const num = ref(1)

    // 减少数量
    const sub = () => {
      num.value--
      if (num.value <= 1) {
        num.value = 1
      }
    }
    // 添加数量
    const add = () => {
      num.value++
      if (num.value > props.maxNum) {
        num.value = props.maxNum
      }
    }
    // 添加购物车或立即购买
    const oper = () => {
      emit('opertion', num.value)
      setTimeout(() => {
        num.value = 1
      }, 1000)
    }
    return {
      num,
      sub,
      add,
      oper
    }
  },
  emits: ['opertion']
}
</script>

<style lang="scss" scoped>
@function rem($px) {
  @return $px / 37.5 * 1rem;
}
.shop-modal {
  .modal-content {
    width: 100%;
    height: rem(263);
    background: white;
    padding: rem(12);
    box-sizing: border-box;

    .card {
      width: 100%;
      display: flex;
      .card-img {
        width: rem(110);
        height: rem(78);

        border-radius: rem(4);
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card-context {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: rem(8);
        .card-title {
          width: rem(233);
          font-size: rem(16);
          font-weight: 400;
          color: #343434;
          line-height: rem(22);
          white-space: nowrap;
          // height: rem(22);
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .card-surplusNum {
          font-size: rem(13);
          font-weight: 400;
          color: #9d9d9d;
          line-height: rem(18);
        }

        .card-price {
          font-size: rem(12);
          font-weight: 500;
          color: #e21d1a;
          span {
            font-size: rem(18);
            font-weight: 500;
            color: #e21d1a;
            line-height: rem(18);
          }
        }
      }
    }
    .oper {
      padding: rem(25) 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .info {
        font-size: rem(14);
        font-weight: 500;
        color: #343434;
        line-height: rem(20);
      }
      .numOper {
        display: flex;
        align-items: center;
        .limit {
          font-size: rem(12);
          font-weight: 400;
          color: #343434;
          line-height: rem(16);
        }
        .btn-item {
          width: rem(28);
          height: rem(26);
          background: #ffffff;
          border-radius: rem(1);
          border: none;
          color: #bfbfbf;
          display: flex;
          align-items: center;
          justify-content: center;
          // font-size: rem(20);
          .add,
          .sub,
          .add_i,
          .sub_i {
            width: 12px;
            height: 12px;
          }
          .add {
            background: url('../../assets/images/home/shopDetail/add.png') no-repeat 0 0/100%;
          }
          .sub {
            background: url('../../assets/images/home/shopDetail/sub_disabled.png') no-repeat 0 0/100%;
          }
          .add_i {
            background: url('../../assets/images/home/shopDetail/add_disabled.png') no-repeat 0 0/100%;
          }
          .sub_i {
            background: url('../../assets/images/home/shopDetail/sub.png') no-repeat 0 0/100%;
          }
        }
        .btn {
          display: flex;
          width: rem(89);
          height: rem(28);
          justify-content: center;
          align-items: center;
          background: #efefef;
          border-radius: rem(1);
          margin-left: rem(9);
          span {
            // display: inline-block;
            width: rem(31);
            text-align: center;
            height: rem(26);
            line-height: rem(26);
            font-size: rem(12);
          }
        }
      }
    }
    .agreement {
      margin-top: rem(100);
    }
    .buy {
      width: 100%;
      height: rem(40);
      display: flex;
      margin-top: rem(15);

      button {
        flex: 1;
        border: none;
        font-size: rem(16);
        font-weight: 400;
        color: #ffffff;
        line-height: rem(22);
        &:last-child {
          border-radius: rem(20);
          background: #ba2927;
        }
      }
    }
  }
}
</style>
