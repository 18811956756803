/*
 * @version: 1.0
 * @Author: 张勇康
 * @Date: 2022-08-03 15:30:03
 */
// 路由配置
import { wechatAuth } from '@/utils/wechatShare.js'
import router from '@/router/config-router'
import store from '@/store/index'
import { init } from './utils/wxPay'
import { Toast } from 'vant'
// 白名单
const whiteList = ['login', 'register', 'forgetPassword'] // 无需登录的页面
// 守卫
router.beforeEach((to, from, next) => {
  // 判断本地缓存
  const userId = localStorage.getItem('hg-org-userId')
  if (userId) {
    if (!sessionStorage.getItem('beforRouter')) {
      sessionStorage.setItem('beforRouter', JSON.stringify(window.location.href))
      // store.commit('beforRouter/getBeforRouter', window.location.href)
    }

    // store.commit('balance/restList', 1)
    if (to.path === '/login' || to.path == '/') {
      if (to.path === '/forgetPassword') {
        return next()
      }
      next('/home')
      return
    }
    const cacheList = to.meta.cacheList
    if (cacheList) {
      if (cacheList.indexOf(from.name) > -1) {
        store.commit('keepAlive/ADD_CACHED_VIEW', to)
      } else {
        store.commit('keepAlive/DEL_CACHED_VIEW', to)
      }
    }
    if (to.query.order) {
      localStorage.setItem('hg-org-userId', JSON.parse(to.query?.order).userId)
      localStorage.setItem('currentOrder', JSON.parse(to.query?.order).currentOrder)
      // return next()
    }
    next()
    // return
    // }
  } else {
    if (to.query.order) {
      localStorage.setItem('hg-org-userId', JSON.parse(to.query?.order).userId)
      localStorage.setItem('currentOrder', JSON.parse(to.query?.order).currentOrder)
      return next()
    }

    if (to.query.userInfo) {
      // console.log(to.query.userInfo, '微信快捷用户未注册')
      if (to.query.userInfo === 'no') {
        Toast('新用户请立即注册！')
        return next({ path: '/login' })
      }
      // console.log(JSON.parse(to.query?.userInfo), '1231321312312')
      localStorage.setItem('hg-org-userId', to.query?.userInfo)
      return next()
    }

    if (whiteList.includes(to.name)) {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})

router.afterEach((to, from) => {
  var ua = navigator.userAgent.toLowerCase()
  var isWeixin = ua.indexOf('micromessenger') != -1
  console.log(isWeixin, 'wx ')
  if (isWeixin && !whiteList.includes(to.name)) {
    const userId = localStorage.getItem('hg-org-userId')

    let authUrl = `${window.location.href}`
    if (window.__wxjs_is_wkwebview) {
      // IOS
      if (window.entryUrl == '' || window.entryUrl == undefined) {
        window.entryUrl = authUrl // 将后面的参数去除
      }
      if (userId) {
        wechatAuth('ios', authUrl)
      }
    } else {
      // 安卓
      setTimeout(function () {
        if (userId) {
          wechatAuth('android', authUrl)
        }
      }, 500)
    }
  }
})

/* 路由异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面 */
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})
