import { getAddr } from '@/api/addr'

export default {
  namespaced: true,
  state() {
    return {
      addrs: [],
      totalItem: 0,
      currentAddr: null
    }
  },
  mutations: {
    getAddr(state, paylod) {
      const { data, totalItem } = paylod
      state.addrs = [...state.addrs, ...data]
      state.totalItem = totalItem
    },
    setCurrentAddr(state, paylod) {
      const obj = state.addrs.find((item) => item.id == paylod.id)
      state.currentAddr = obj || paylod
    },
    setDefaultAddr(state) {
      state.currentAddr = state.addrs.find((item) => item.defaulted) || null
    },
    clearAddr(state) {
      state.addrs = []
      state.totalItem = 0
    }
  },
  actions: {
    async getAddr({ commit }, paylod) {
      const { pageIndex, pageSize } = paylod
      const { data, totalItem } = await getAddr({ pageIndex, pageSize })

      commit('getAddr', { data, totalItem })
    }
  }
}
