const state = () => ({
  beforRouter: ''
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  getBeforRouter(state, params) {
    state.beforRouter = params
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
