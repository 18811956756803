<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: 周达
 * @Date: 2022-09-13 09:11:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-13 09:18:52
-->
<template>
  <van-popup position="bottom" class="select-box" @close="close" round>
    <van-picker
      ref="picker"
      visible-item-count="3"
      item-height="1.2rem"
      :title="topTitle"
      :default-index="0"
      :columns="selectOptions"
      @cancel="close"
      @confirm="switchType"
    />
  </van-popup>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'lSelect',
  props: {
    topTitle: {
      type: String,
      default: '余额明细'
    },
    selectOptions: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    },
    operateType: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const activeIndex = ref(0)
    const picker = ref(null)
    const switchType = (item) => {
      emit('changeHandle', item.value)
      emit('update:show', false)
    }

    const close = () => {
      emit('update:show', false)
      resetPickerValue()
    }
    const resetPickerValue = () => {
      const index = props.selectOptions.findIndex((item) => props.operateType === item.value)
      if (picker.value) {
        picker.value.setColumnIndex(0, index)
      }
    }
    return {
      switchType,
      activeIndex,
      close,
      picker
    }
  },
  emits: ['changeHandle', 'update:show']
}
</script>

<style lang="scss" scoped>
@function rem($px) {
  @return $px / 37.5 * 1rem;
}
.select-box {
  .select-list {
    width: 100%;
    // height: rem(335);
    border-radius: rem(12) rem(12) 0px 0px;
    overflow: hidden;
    // padding-bottom: 16px;
    .list-item {
      width: 100%;
      height: rem(56);
      background: #ffffff;
      font-size: rem(17);
      font-weight: 400;
      color: #343434;
      line-height: rem(24);
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: rem(1) solid rgba(0, 0, 0, 0.1);
    }
    .active {
      color: #ba2927;
    }
  }
}
</style>
