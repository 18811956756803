import { nextTick } from 'vue'
export default () => {
  document.addEventListener('visibilitychange', () => {
    console.log(document.hidden, '熄屏')
    if (!document.hidden) {
      nextTick(() => {
        const el = document.activeElement
        if (el.nodeName === 'INPUT') {
          el.blur()
          console.log('input聚焦')
          setTimeout(() => {
            el.focus()
          }, 400)
        }
      })
    }
  })
  // const iframe = document.createElement('iframe')
  // iframe.style.display = 'none'
  // iframe.setAttribute(
  //   'src',
  //   'https://wx.tenpay.com/cgi-bin/mmpayweb-bin/checkmweb?prepay_id=wx211512447268249fd12f2c60ea6e120000&package=3806122785'
  // )
  // iframe.setAttribute('id', 'iframePay')
  // iframe.setAttribute('sandbox', 'allow-top-navigation allow-scripts')
  // document.body.appendChild(iframe)

  // iframe.onload = () => {
  //   console.log(iframe)
  // }
}
