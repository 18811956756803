/*
 * @version: 1.0
 * @Author: 张勇康
 * @Date: 2022-08-04 16:48:00
 */
import axios from '@/utils/request'

const apis = {
  register: '/drinks/app_no_login/v1/user/register', // 注册
  encryption: '/uic/open/getEncryptedString', // 获取加密参数
  getCodes: `/drinks/direct/smsCode`, // 获取验证码
  verifyCode: '/drinks/smsCode/verify',
  login: 'drinks/login/v1/in', // 登录
  resetPsaaword: '/uic/web/user/reset/password/bycode', // 修改密码,
  getMineInfo: '/drinks/app/v1/user',
  getMyAchievement: '/drinks/app/v1/agent/detil', // 查看app我的业绩
  getMyTeamAchievement: '/drinks/app/v1/agent/teamdetil', // 查看app我的业绩，
  getShareQrCode: '/drinks/app/v1/user/qrcode'
}
export function register(params) {
  return axios({
    url: apis.register,
    method: 'POST',
    data: params
  })
}

export function encryption(params) {
  return axios({
    url: apis.encryption,
    method: 'GET',
    data: params
  })
}

export function getCodes(params) {
  return axios({
    url: apis.getCodes,
    method: 'POST',
    data: params
  })
}
export function verifyCode(params) {
  return axios({
    url: apis.verifyCode,
    method: 'POST',
    data: params
  })
}
export function login(params) {
  return axios({
    url: apis.login,
    method: 'post',
    data: { ...params, json: 1, loginType: 100, style: 'drinks', encrypt: true, from: 4 },
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export function resetPsaaword(params) {
  return axios({
    url: apis.resetPsaaword,
    method: 'PUT',
    data: params
  })
}
export function getMineInfo(params) {
  return axios({
    url: apis.getMineInfo,
    method: 'GET',
    params
  })
}
export function getMyAchievement(params) {
  return axios({
    url: apis.getMyAchievement,
    method: 'GET',
    params
  })
}
export function getMyTeamAchievement(params) {
  return axios({
    url: apis.getMyTeamAchievement,
    method: 'GET',
    params
  })
}
export function getShareQrCode(params) {
  return axios({
    url: apis.getShareQrCode,
    method: 'GET',
    params
  })
}
export default {
  login,
  getCodes,
  encryption,
  register,
  resetPsaaword,
  getMineInfo,
  verifyCode
}
