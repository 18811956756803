import request from '@/utils/request'
const apis = {
  addr: 'drinks/app/v1/address'
}
export const getAddr = (params) => {
  console.log(params)
  return request({
    method: 'GET',
    url: apis.addr,
    params
  })
}

export const addAddr = (data) => {
  return request({
    method: 'POST',
    url: apis.addr,
    data
  })
}

export const editAddr = (data) => {
  return request({
    method: 'PUT',
    url: apis.addr,
    data
  })
}
export const delAddr = (id) => {
  return request({
    method: 'DELETE',
    url: `${apis.addr}/${id}`
  })
}
