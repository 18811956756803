import request from '@/utils/request'

const apis = {
  shop: '/drinks/app/v1/spu'
}

export const getShop = (params) => {
  return request({
    method: 'GET',
    params,
    url: apis.shop
  })
}
export const getShopDetail = (id) => {
  return request({
    method: 'GET',
    url: apis.shop + `/${id}`
  })
}
