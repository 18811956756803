// import { Toast } from 'vant'
import wx from 'weixin-jsapi'
import { getWxOrder } from '@/api/pay'
// import { useStore } from 'vuex'
import store from '@/store'
import { getSource } from './browser'

export const init = (userId) => {
  console.log(process.env.VUE_APP_API_APPID, 'appid')
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
    process.env.VUE_APP_API_APPID
  }&redirect_uri=${process.env.VUE_APP_API_REDIRECT_URI}&response_type=code&scope=${
    userId ? 'snsapi_base' : 'snsapi_userinfo'
  }&state=${encodeURI(JSON.stringify({ userId, url: location.origin + '/home' }))}`
  // console.log()
  location.href = url
}

export const setConfig = async (params) => {
  return new Promise((resolve, reject) => {
    getWxOrder(params)
      .then((response) => {
        // const data = response.data
        // const store = useStore()
        if (response) {
          const data = response.data
          console.log(data, 'configPay')
          store.commit('shopCart/setConfig', data)
          resolve(data)
          wx.config({
            debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
          })
        } else {
          // Toast({
          //   // message: '获取支付信息失败，请重试'
          // })
          reject()
        }
      })
      .catch((err) => {
        console.log(err)
        // Toast('获取订单信息失败，请重试')
        reject()
      })
  })
}

// 配置拉起jsapi支付支付
export const toapply = (data) => {
  return new Promise((resolve, reject) => {
    console.log(process.env.VUE_APP_API_REDIRECT_URI, data, 'payinfo111111')
    wx.ready(function () {
      wx.chooseWXPay({
        appId: data.appId,
        timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: data.nonceStr, // 支付签名随机串，不长于 32
        package: `prepay_id=${data.prepayId}`, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: data.paySign, // 支付签名
        success: function (res) {
          resolve(res)
        },
        cancel: function (res) {
          // 提示引用的是mint-UI里toast
          console.log(res)
          reject()
          // Toast('已取消支付')
        },
        fail: function (res) {
          console.log(res)
          reject()
          // Toast('支付失败，请重试')
        }
      })
    })

    wx.error((err) => {
      console.log(err, 99999999)
    })
    // console.log(response)
  })
}

// h5支付
export const playH5 = async (params, query) => {
  const soruce = getSource()
  if (soruce !== 'safari') {
    window.location.hash = '#123'
  }
  console.log(navigator.userAgent)
  return new Promise((resolve, reject) => {
    getWxOrder(params).then((response) => {
      console.log(response)
      if (response.success) {
        store.commit('shopCart/setConfig', response.data)
        console.log(soruce)
        const hgOrgUserId = localStorage.getItem('hg-org-userId')
        const currentOrder = localStorage.getItem('currentOrder')
        if (soruce == 'safari') {
          window.location.href =
            response.data.url +
            `&redirect_url=${encodeURIComponent(
              `${location.origin}/${query ? query.path : 'orderDetail/' + params.orderId}?order=${encodeURIComponent(
                JSON.stringify({
                  orderNo: response.data.orderNo,
                  orderId: params.orderId,
                  userId: hgOrgUserId || '',
                  currentOrder: currentOrder || '',
                  payMode: query?.payMode || ''
                })
              )}`
            )}`
        } else {
          const iframe = document.createElement('iframe')
          iframe.style.display = 'none'
          iframe.setAttribute('src', response.data.url)
          iframe.setAttribute('id', 'pay')
          iframe.setAttribute('sandbox', 'allow-top-navigation allow-scripts')
          document.body.appendChild(iframe)
          iframe.onload = () => {
            console.log(top.location.href)
            // window.top.postMessage({ success: true, pay_url: pay_url }, '*')
          }
        }
      } else {
        reject()
      }
    })
  })
}
