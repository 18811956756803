import { getUserInfo } from '@/api/accountManagement'

export default {
  namespaced: true,
  state() {
    return {
      userInfo: null,
      random: Math.random() * 10000000
    }
  },
  mutations: {
    setUserInfo(state, paylod) {
      const { data } = paylod
      state.userInfo = data
    }
  },
  actions: {
    async getUserInfo({ commit }) {
      //   const { pageIndex, pageSize } = paylod
      const { data } = await getUserInfo()
      commit('setUserInfo', { data })
    }
  }
}
