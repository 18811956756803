const state = () => ({
  informationData: [
    {
      id: 1,
      picUrl: require('@/assets/images/home/list_img_02.jpg'),
      title: '2022年9月，宁波善财商贸公司筹办的财神谷酒品酒会在当地盛大举行',
      content:
        '2022年9月6日，财神谷酒宁波地区品酒会在宁波国大雷迪森广场酒店盛大举行。 宁波善财公司一直秉承着“善行天下，财满万家”的核心理念，誓做良心产品，弘扬社会正能量。公司出品的“财神酒”酱香突出，酒体醇厚，回味悠长，空杯留香。',
      createTime: '2022-10-14 11:33:40'
    },
    {
      id: 2,
      picUrl: require('@/assets/images/home/list_img_01.jpg'),
      title: '“善行天下，财满万家”善财商贸有限公司举行财神谷酒代理团建活动！',
      content:
        '宁波善财商贸有限公司秉承“善行天下•财满万家”的核心理念，践行团结、友爱、互助的企业文化，组织区代家人在台州临海“羊岩山庄度假酒店”举办财神谷酒代理商第一次团建活动。',
      createTime: '2022-10-14 11:33:40'
    }
  ],
  details: [
    {
      id: 1,
      detail: `
      <p class="title">2022年9月，宁波善财商贸公司筹办的财神谷酒品酒会在当地盛大举行！</p>
      <p class="time">2022-10-14 11:33:40<span>发布</span></p>
      <div class="context">2022年9月6日，财神谷酒宁波地区品酒会在宁波国大雷迪森广场酒店盛大举行。宁波善财公司一直秉承着“善行天下，财满万家”的核心理念，誓做良心产品，弘扬社会正能量。公司出品的“财神酒”酱香突出，酒体醇厚，回味悠长，空杯留香。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/information/information_01.jpg')}" alt="" />
      </div>
      <div class="context">“财神酒”不仅是人与人，也是人与天地，人与神灵交流的工具，它不仅赋予我们更多的才华和激情。更会给我们带来财气、贵气、好运气！“喝财神谷酒，财神牵您手，富贵跟您走”现场嘉宾热情参与、积极交流、以酒会友、洽谈商机。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/information/information_02.jpg')}" alt="" />
        <img src="${require('@/assets/images/home/information/information_03.jpg')}" alt="" />
      </div>
      `
    },
    {
      id: 2,
      detail: `
      <p class="title">“善行天下，财满万家”善财商贸有限公司举行财神谷酒代理团建活动！</p>
      <p class="time">2022-10-14 11:33:40<span>发布</span></p>
      <div class="context">宁波善财商贸有限公司秉承“善行天下•财满万家”的核心理念，践行团结、友爱、互助的企业文化，组织区代家人在台州临海“羊岩山庄度假酒店”举办财神谷酒代理商第一次团建活动。本次活动意义重大，不仅兑现了承诺，也规划畅想未来的发展。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/information/information_04.jpg')}" alt="" />
        <img src="${require('@/assets/images/home/information/information_05.jpg')}" alt="" />
        <img src="${require('@/assets/images/home/information/information_06.jpg')}" alt="" />
      </div>
      
      `
    }
  ],
  informationDetail: null
})

// getters
const getters = {}

// actions
const actions = {
  getInformationDetail({ commit, state }, paylod) {
    const detail = state.details.find((item) => item.id === paylod)
    commit('setInformationDetail', detail)
  }
}

// mutations
const mutations = {
  setInformationDetail(state, paylod) {
    state.informationDetail = paylod
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
